import Congr from "./congrats.png"
import "./index.scss"
function App() {
  return (
    <div className="wrapper">
      <div className="app">
        <img src={Congr} width="150" />
        <h1 className="headings">Tabriklaymiz!</h1>
        <h2 className="headings">Siz muvaffaqiyatli ro'yhatdan o'tdingiz!</h2>
        <h3 className="headings">inshaAllah tez orada siz bilan bog'lanamiz.</h3>
      </div>
    </div>
  );
}

export default App;
