import React from 'react';
import ReactDOM from 'react-dom/client';
// import App from './App';
import App2 from './App2';
import "./index.scss"

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <div className='root'>
    {/* <App /> */}
    <App2 />
  </div>
);
