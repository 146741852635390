import React, { useState } from "react";
import Telegram from "telegram-send-message";
import App from "./App";

const App2 = () => {
  const [answers, setAnswers] = useState({
    from_name: "",
    comment: "",
    message: "",
    tg_name: "",
    phoneNumber: "",
  });
  const [check, setCheck] = useState(false);
  // const [isTugadi, setIsTugadi] = useState(false)

  const emailjs = ({ message, from_name, comment, tg_name, phoneNumber }) => {
    if (!tg_name.includes("@")) {
      tg_name = "@" + tg_name;
    }
    if ((message.length > 0 && from_name.length > 0, phoneNumber.length > 0)) {
      Telegram.setToken("6048875588:AAEGebgWHn8p0_Sw95FEFQ9rb911px-JetU");
      Telegram.setRecipient(1167233264);
      Telegram.setMessage(`Full Name: ${from_name}   ⌘
   Telegram username: ${tg_name}   ⌘
   Message: ${message}   ⌘
   Comment: ${comment}   ⌘
   Phone: ${phoneNumber}`);
      Telegram.send();
      localStorage.clear();
      sessionStorage.clear();
      setTimeout(() => {
        JSON.stringify(sessionStorage.setItem("user", true));
        window.location.reload();
      }, 1000);
    } else {
      alert("Malumotlarni to'ldiring!");
    }
  };
  const handleChange = (e) => {
    setAnswers((p) => ({ ...p, [e.target.name]: e.target.value }));
  };
  if (JSON.parse(sessionStorage.getItem("user")) === true) {
    return <App />;
  }
  return (
    <div className="wrapper">
      <h1 style={{ textAlign: "center" }}>incode | course</h1>
      <p style={{ textAlign: "center" }}>Kursga ro'yhatdan o'tish</p>
      <div className="flexible">
        <input
          required
          name="from_name"
          onChange={(e) => handleChange(e)}
          type="text"
          placeholder="To'liq ismingiz"
        />
        <textarea
          cols="40"
          required
          name="message"
          onChange={(e) => handleChange(e)}
          placeholder="IT dagi maqsadingiz qisqacha"
        ></textarea>
        <textarea
          cols="40"
          rows="5"
          name="comment"
          onChange={(e) => handleChange(e)}
          placeholder="Nega bu kursga rozi boldingiz? Nima sabab bo'ldi? Bu kursni sizga kim taklif qildi?"
        ></textarea>
        <div className="for_sobachka">
          <span className="sobachka">@</span>
          <input
            style={{ paddingLeft: "50px" }}
            required
            name="tg_name"
            onChange={(e) => handleChange(e)}
            type="text"
            placeholder="Telegram usernamingiz"
          />
        </div>
        <input
          required
          name="phoneNumber"
          onChange={(e) => handleChange(e)}
          type="number"
          placeholder="Telefon raqamingiz: "
        />
        <div>
          <div className="link">
            <a
              className="link"
              href="https://telegra.ph/incode-kursi-boyicha-batafsil-malumot-03-13"
            >
              👉 Kurs boyicha batafsil malumotlar
            </a>
          </div>
          <div
            style={{
              transform: "scale(1.5)",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginTop: "20px",
              justifyContent: "center",
            }}
          >
            <input
              required
              className="check"
              type="checkbox"
              name="rozi"
              onChange={() => setCheck(!check)}
              width="50px"
            />
            <p>Hammasini o'qib chiqdim</p>
          </div>
        </div>
      </div>
      {check && (
        <button onClick={() => emailjs({ ...answers })}>
          Ro'yhatdan o'tish
        </button>
      )}
    </div>
  );
};

export default App2;
